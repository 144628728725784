<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <el-form class="manageForm" :model="params" :inline="true">
        <el-form-item prop="EnterpriseFullName">
          <el-input placeholder="请输入内容" v-model="params.EnterpriseFullName" @keyup.enter.native="search()">
            <el-select v-model="params.currentEnterprise" slot="prepend">
              <el-option label="货主及服务商单位" value="1"></el-option>
              <el-option label="货主单位" value="2"></el-option>
              <el-option label="服务商单位" value="3"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="开票日期：" prop="date">
          <el-date-picker v-model="params.TaskDatetime" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd" @change="dateChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开票申请编号：" prop="InvoiceTaskID">
          <el-input class="fromInp" v-model="params.InvoiceTaskID" placeholder="输入开票申请编号" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="发票类型：" prop="InvoiceType">
          <el-select v-model="params.InvoiceType" clearable placeholder="请选择发票类型" @change="search()">
            <el-option v-for="item in options" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          <el-button type="primary" :disabled="selectedTableItemList == '' ? true : false" size="medium"
            @click="uploadClick()" icon="el-icon-download">批量下载</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
        @selection-change="tableSelectionChange">
        <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="180" fixed="left"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplicantUserName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PrincipalCompanyName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="开票完成时间" width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="总计运单数量" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TransportCosts | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="货主付款总金额" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayCompleteDatetime" label="货主付款时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="平台技术服务费" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">￥0.00</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="remark" label="电子回单" fixed="right" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="uploadClick(scope.row)" icon="el-icon-download">
              下载回单
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-document">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="maring-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page"
          :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { tasksInfoList, taskSummary, downloadBankUrl } from "@/api/finance/account/index";
import { getDataDict } from "@/api/common/common.js";
export default {
  data() {
    return {
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      // 选择开票日期
      params: {
        TaskDatetime: ['', ''],
        InvoiceTaskID: "",
        EnterpriseFullName: '',
        currentEnterprise: '1',
        InvoiceType: ''
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 历史资金汇总
      invoices: {},
      // 表格loading
      loading: true,
      // 需要下载的列表
      selectedTableItemList: ''
    };
  },
  created() {
    this.pagination.page = this.$route.query.pageIndex ? Number(this.$route.query.pageIndex) : 1
    this.params.TaskDatetime[0] = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.params.TaskDatetime[1] = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    this.params.InvoiceTaskID = this.$route.query.InvoiceTaskID ? this.$route.query.InvoiceTaskID : ''
    this.params.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : '1'
    this.params.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.params.InvoiceType = this.$route.query.InvoiceType || ''
    // 获取结算信息列表
    this.tasksInfoList();
    // 汇总开票相关信息
    // this.taskSummary();
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    dateChange() {
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/finance/payment/index') {
        // 重置路由
        this.$router.push({
          path: "/finance/payment/index"
        });
      }
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e
    },
    // 下载电子回单
    uploadClick(item) {
      this.loading = true;
      if (item) {
        this.selectedTableItemList = [item]
      }
      let json = {
        InvoiceTaskIDs: [],
        WaybillIDs: []
      }
      this.selectedTableItemList.forEach((item) => {
        json.InvoiceTaskIDs.push(item.InvoiceTaskID)
      });
      downloadBankUrl(json).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    // 条件搜索
    search() {
      this.loading = true
      this.pagination.page = 1;
      this.tasksInfoList();
    },
    // 清空条件搜索
    resetForm() {
      this.loading = true
      this.pagination.page = 1;
      this.params = {
        TaskDatetime: ['', ''],
        EnterpriseFullName: '',
        InvoiceTaskID: "",
        currentEnterprise: '1',
        InvoiceType: ''
      }
      this.tasksInfoList();
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        RoleType: 0,
        InvoiceType: this.params.InvoiceType
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data;
      });
    },
    // 获取结算信息列表
    tasksInfoList() {
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
        EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
        InvoiceTaskID: this.params.InvoiceTaskID ? this.params.InvoiceTaskID : "",
        EnterpriseFullName: this.params.currentEnterprise == 1 ? this.params.EnterpriseFullName : '',
        DemanderName: this.params.currentEnterprise == 2 ? this.params.EnterpriseFullName : '',
        SupplierName: this.params.currentEnterprise == 3 ? this.params.EnterpriseFullName : '',
        RoleType: 0,
        InvoiceType: this.params.InvoiceType
      };
      tasksInfoList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        // this.taskSummary()
      }).finally(() => {
        this.loading = false;
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      this.pagination.page
      this.tasksInfoList()
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/finance/payment/paymentinfo",
        query: {
          InvoiceTaskID: item.InvoiceTaskID,
          pageIndex: this.pagination.page,
          StartDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[0] : '',
          EndDatetime: this.params.TaskDatetime ? this.params.TaskDatetime[1] : '',
          currentEnterprise: this.params.currentEnterprise,
          EnterpriseFullName: this.params.EnterpriseFullName ? this.params.EnterpriseFullName : '',
          InvoiceType: this.params.InvoiceType
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accountWrap {
  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    padding: 0 32%;
    text-align: center;

    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}
</style>